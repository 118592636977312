<template>
  <div>
    <top-nav></top-nav>
    <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-4">
      <h2 class="pageHeaderText mt-3 mb-3">JOIN</h2>

      <tabs
          class="mb-3 mt-3"
          :data="['TOURNAMENT', 'WEIGH IN']"
          main-color="#000000"
          @clickedTab="tabsHandler"
        >
        </tabs>

        <!-- TOURNAMENT TAB  -->
        <div v-if="this.tabIndex == 0">
          <b-card
              id="joinCard"
              class="ml-2 mr-2"
              bg-variant=""
              text-variant="white"
            >
            <h3 class="text-white">TOURNAMENT</h3>
            <ul class="pl-3">
              <li class="joinInfoText text-left">Join a <strong>CastClash</strong> tournament as a participant.</li> 
              <li class="joinInfoText text-left">Watch the standings of a LIVE tournament.</li>
              <li class="joinInfoText text-left">View past results.</li>
            </ul>

            <b-form-group
              class="mt-3 text-white text-left"
              label="Event Code:"
              label-size="md"
              label-for="eventID"
            >
              <b-form-input
                style="height: 60px"
                class="joinEventIdInput"
                id="eventID"
                placeholder="Event Code"
                size="lg"
                v-model="eventID"
                required
                trim
                :formatter="formatter"
              ></b-form-input>
            </b-form-group>
            <b-button
              id="joinButton"
              class="joinButton mt-3 w-100"
              variant="success"
              size="lg"
              @click="getEventData()"
              :disabled="idState"
            >
              JOIN
            </b-button>
            <h4 class="mt-3 text-warning">{{ this.eventMessage }}</h4>
          </b-card>
        </div>


        <!-- WEIGH IN TAB  -->
      <div v-if="this.tabIndex == 1">
        <b-card
            id="joinCard"
            class="ml-2 mr-2"
            bg-variant=""
            text-variant="white"
          >
          <h3 class="text-white">WEIGH IN</h3>
          <ul class="pl-3">
            <li class="joinInfoText text-left">Watch the standings <strong>LIVE</strong>.</li>
            <li class="joinInfoText text-left">View past results.</li>
            <li class="joinInfoText text-left"><strong>Tournament Directors</strong> - Run your weigh in using the <strong>Admin code</strong>.</li>
          </ul>

          <b-form-group
            class="mt-3 text-white text-left"
            label="Event Code"
            label-size="md"
            label-for="eventID"
          >
            <b-form-input
              style="height: 60px"
              class="joinEventIdInput"
              id="eventID"
              placeholder="Event Code"
              size="lg"
              v-model="eventID"
              required
              trim
              :formatter="formatter"
            ></b-form-input>
            <!-- v-on:keyup.enter="getEventData()" -->
          </b-form-group>
          <b-form-group
            class="mt-3 text-white text-left"
            label="Admin Code (optional)"
            label-size="md"
            label-for="adminID"
          >
            <b-form-input
              style="height: 60px"
              class="joinEventIdInput"
              id="adminID"
              placeholder="Admin Code"
              size="lg"
              v-model="adminID"
              required
              trim
              :formatter="formatter"
            ></b-form-input>
          </b-form-group>
          <b-button
            id="joinButton"
            class="joinButton mt-3 w-100"
            variant="success"
            size="lg"
            @click="getEventData()"
            :disabled="idState"
          >
            JOIN
          </b-button>
          <h4 class="mt-3 text-warning">{{ this.eventMessage }}</h4>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import TopNav from '../components/TopNav.vue';
import Tabs from '../components/UnderlineTabs.vue';

export default {
  components: {
    TopNav,
    Tabs
  },
  data() {
    return {
      eventID: '',
      adminID: '',
      eventMessage: '',
      tabIndex: 0
    };
  },
  mounted() {
    localStorage.id = '';
    localStorage.activeUser = '';
    this.$store.commit('UNLOAD_TOURNAMENT');

    // if (process.env.NODE_ENV === 'development') {
    //   // Code for development environment
    //   console.log('Development environment');
    // } else if (process.env.NODE_ENV === 'production') {
    //   // Code for production environment
    //   console.log('Production environment');
    // } else {
    //   // Code for other environments
    //   console.log('Other environment');
    // }
  },
  watch: {
    eventQueryInfo(value) {
      console.log(value);
      this.joinEvent();
    },
  },
  computed: {
    eventQueryInfo() {
      return this.$store.getters['getEventQueryInfo'];
    },
    eventExists() {
      return this.$store.getters['getEventExistsStatus'];
    },
    loadedEvent() {

    },
    eventDate() {
      return this.$store.getters['getEventDate'];
    },
    idState() {
      return this.validateIDfield();
    }
  },
  methods: {
    
    formatter(value) {
      return value.toUpperCase();
    },
    validateIDfield() {
      //Make sure something is in the field before pressing GO
      if (this.eventID == '') {
        return true;
      } else {
        return false;
      }
    },
    getEventData() {
      this.$store.commit('UNLOAD_TOURNAMENT');
      this.$store.dispatch('queryEventInfo', {eventID: this.eventID, adminID: this.adminID});
    },
    joinEvent() {

      if (!this.eventQueryInfo.eventExists) {
        this.invalidEventEntered('Invalid Event Code');
      }
      if (this.eventQueryInfo.eventType == "TOURNAMENT") {
        this.joinTournament();
      }
      if (this.eventQueryInfo.eventType == "WEIGH-IN") {

        if (this.eventQueryInfo.isAdmin == true || this.eventQueryInfo.isAdmin == '') {
          this.joinWeighIn();
        } else {
          this.invalidEventEntered('Invalid Admin Code');
        }
      }
    },
    joinTournament() {
      let today = new Date();
      today = moment(today).format('YYYY-MM-DD');
      
      // IF TODAY IS THE DAY OF THE TOURNAMENT
      // TAEK THEM TO THE WELCOME SCREEN
      if (this.eventQueryInfo.eventDate == today) {
        localStorage.id = this.eventID;
        this.$router.push({ path: 'welcome' });
      } 
      // IF IT IS NOT THE DAY OF THE TOURNAMENT
      // TAKE THEM TO THE LIVE SCORER IN VIEW ONLY MODE
      else {
        localStorage.activeUser = "VIEW ONLY";
        localStorage.id = this.eventID;
        this.$router.push({ path: 'live-scorer' });
      }
    },
    joinWeighIn() {
      localStorage.id = this.eventID;

      // IF NO ADMIN CODE IS ENTERED
      if (this.adminID == '') {
        localStorage.activeUser = "VIEW ONLY";
        this.$router.push({ path: 'live-weighin' });
      } else {

        // MAKE SURE THE ADMIN CODE IS CORRECT
        if (this.eventQueryInfo.isAdmin) {
          console.log('VALID ADMIN');
          localStorage.activeUser = "ADMIN";
          this.$router.push({ path: 'live-weighin' });
        } else {
          console.log('INVALID ADMIN CODE');
          this.invalidEventEntered('Invalid Admin Code.')
        }
      }

    },
    invalidEventEntered(errorMessage) {
      this.eventMessage = errorMessage;
    },
    tabsHandler(selectedTabData) {
      this.tabIndex=  selectedTabData.index;
    }
  },
};
</script>
<style>
  #joinCard {
    border: 1px solid var(--color30);
    background-color: var(--supplemental-color);
  }
  .joinInfoText {
    font-family: var(--primary-font);
    color: white;
    font-size: 1.25em;
    
  }
  .joinEventIdInput {
    font-size: 1em;
  }
  .joinButton {
    height: 60px;
  }

</style>
