<template>
  <div>
    <top-nav></top-nav>
    <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <h2 class="pageHeaderText m-3">WELCOME TO YOUR CASTCLASH TOURNAMENT</h2>
      <p class="welcome-secondaryText mt-3">
        Please select your TEAM NAME to join the tournament.
      </p>
      <b-card
          id="welcomeCard"
          class="ml-2 mr-2"
          bg-variant=""
          text-variant="white"
        >
          <b-row class="d-flex justify-content-center">
            <b-button
              id="viewOnly"
              class="mt-1 w-75"
              variant="warning"
              size="lg"
              @click="goToLiveScorer('VIEW ONLY')"
            >
              VIEW ONLY
            </b-button>
          </b-row>
          <b-row
            class="d-flex justify-content-center"
            v-for="team in eventTeams"
            :key="team.name"
          >
            <b-button
              id="team"
              class="mt-3 w-75"
              variant="success"
              size="lg"
              @click="goToLiveScorer(team.name)"
            >
              {{ team.name }}
            </b-button>
          </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav,
  },
  created() {
    if (localStorage.id == '') {
      this.$router.push({ path: '/' });
    }
  },
  mounted() {
    // load all the teams.
    this.$store.dispatch('loadTeams', localStorage.id);
  },
  computed: {
    eventTeams() {

      let teams = this.$store.getters['getEventTeams'];
      // sort the teams alphabetically
      teams.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      return teams;
    },
  },
  methods: {
    goToLiveScorer(team) {
      localStorage.activeUser = team;
      localStorage.addFishEntryType = 'pounds';
      this.$router.push({ path: 'live-scorer' });
    },
    goToNotifications(team) {
      localStorage.activeUser = team;
      localStorage.addFishEntryType = 'pounds';
      this.$router.push({ path: 'notifications' });
    },
    checkIfEventIsToday() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + '-' + mm + '-' + dd;

      if (today == this.$store.getters['getEventDate']) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
#welcomeCard {
    border: 1px solid var(--color30);
    background-color: var(--supplemental-color);
  }
.welcome-secondaryText {
  color: var(--primary-font-color);
  font-size: 1.25em;
  margin: 0;
}
</style>
