import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

// import vueTabz from 'vue-tabz';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VuejsDialog);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import './css/custom.css';

// import './registerServiceWorker'

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {},
}).$mount('#app');
