<template>
  <div>
    <b-card id="coffee" class="ml-3 mr-3 mt-3 mb-5 text-white">
      <h5 class="mt-0 mb-0">Enjoying <strong>CastClash</strong>? Please buy us a coffee and help us keep the lights on.</h5>
      <buy-me-a-coffee></buy-me-a-coffee>
    </b-card>
  </div>
</template>

<script>
import BuyMeACoffee from './BuyMeACoffee.vue'
export default {
  name: 'BuyMeACupOfCoffeeCard',
  components: {
    BuyMeACoffee
  }
};
</script>

<style>
#coffee {
  border: 1px solid var(--color30);
  background-color: #2a8e42;
}
</style>