<template>
  <div id="leaderboard">
    <team-data-modal :teamName="this.clickedTeam"></team-data-modal>

    <!-- COLUMN HEADERS -->
    <b-container fluid class="p-0">
      <b-row no-gutters class="mt-2 mb-2">
        <b-col cols="2">
          <p class="lb-colNames"></p>
        </b-col>
        <b-col cols="5">
          <p class="lb-colNames text-left">TEAM</p>
        </b-col>
        <b-col cols="2">
          <p class="lb-colNames text-left">BIG</p>
        </b-col>
        <b-col cols="3">
          <p class="lb-colNames text-right">TOTAL</p>
        </b-col>
      </b-row>
    </b-container>
    <!-- ROWS OF STANDINGS -->
    <div
      v-for="place in standings"
      :key="place.name"
    >
      <b-card
        id="standing"
        v-b-modal.team-data
        class="mb-2"
        no-body
        @click="cardClicked(place.name)"
      >
        <b-container>
          <b-row class="d-flex justify-content-between">
            
            <b-col class="pl-0 pr-0" cols="2">
              <p id="place" class="lb-teamPlace text-center">{{ place.place }}</p>
            </b-col>

            <b-col class="pl-0 pr-0" cols="5">
              <p id="teamName" class="text-left lb-teamName">{{ place.name }}</p>
              <p id="fishCount"class="text-left lb-fishCount">{{ place.catches }} FISH</p>
            </b-col>

            <b-col class="pl-0 pr-0" cols="2">
              <div v-if="place.big == bigFish">
                <p id="bigFish" class="text-left lb-bigFishOwner">{{ place.big }}</p>
              </div>
              <div v-else>
                <p id="bigFish" class="text-left lb-bigFish">{{ place.big }}</p>
              </div>
            </b-col>

            <b-col class="pl-0 pr-0" cols="3">
              <p id="totalWeight" class="lb-totalWeight text-right mr-2"> {{ place.total }} </p>
              <p id="behindWeight" class="lb-behindWeight text-right mr-2"> -{{ place.weightBehind }} </p>
            </b-col>

          </b-row>
        </b-container>
      </b-card>
    </div>
  </div>
</template>

<script>
import TeamDataModal from './TeamDataModal.vue';
export default {
  components: {
    TeamDataModal,
  },
  name: 'LeaderBoard',
  props: ['standings'],
  data() {
    return {
      show: false,
      clickedTeam: '',
      teamCatches: [],
    };
  },
  computed: {
    bigFish() {
      let bigFish = this.$store.getters['getBigFish'];
      return bigFish.weight;
    },
  },
  methods: {
    onRowClicked(row) {
      this.clickedTeam = row.name;
      this.$root.$emit('bv::toggle::collapse', 'show-catches');
    },
    firstPlaceRowClass(item) {
      if (item.place == 1) {
        return 'table-success';
      }
    },
    cardClicked(name) {
      this.clickedTeam = name;
    },
  },
};
</script>
<style>
#standing {
  border: 1px solid var(--color30);
  /* background-color: var(--color60); */
  background-color: var(--supplemental-color);
}

.lb-colNames {
  font-family: var(--primary-font);
  color: var(--color30);
  font-size: 0.8em;
  font-weight: 200;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.lb-teamPlace {
  /* font-family: var(--leaderboard-font); */
  /* color: var(--primary-font-color); */
  /* font-size: 1.15em; */
  /* font-weight:bolder; */
  /* margin: 0 0 0 0; */
  /* padding: 0 0 0 0; */

  font-family: var(--leaderboard-font);
  color: var(--color10);
  font-size: 1.5em;
  font-weight:bolder;
  margin: 0 0 0 0;
  /* padding: 0 0 0 0; */
}

.lb-teamName {
  font-family: var(--leaderboard-font);
  font-size: 1.05em;
  font-weight:bold;
  color: var(--primary-font-color);
  /* font-size: 1.25em; */
  margin: 0;
  padding: 0;
}

.lb-bigFishOwner {
  font-family: var(--leaderboard-font);
  font-size: 1.0em;
  font-weight: bold;
  color: var(--color30);
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.lb-bigFish {
  font-family: var(--leaderboard-font);
  font-size: .9em;
  font-weight: normal;
  color: var(--primary-font-color);
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.lb-totalWeight {
  font-family: var(--leaderboard-font);
  font-size: 1.00em;
  font-weight: bold;
  color: var(--primary-font-color);
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.lb-behindWeight {
  font-family: var(--leaderboard-font);
  font-size: .9em;
  font-weight: 500;
  color: var(--color10);
  margin: 0;
  padding: 0;
}
.lb-fishCount {
  font-family: var(--leaderboard-font);
  font-size: .9em;
  font-weight: 500;
  color: var(--primary-font-color);
  margin: 0;
  padding: 0;
}


/* SHOW CATCHES COMPONENT =================== */
.showCatches-table {
  table-layout: fixed;
  width: '50%';
}

.showCatches-tableFont {
  color: #000000;
}
</style>
