<template>
  <div>
    <top-nav></top-nav>
    <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-4">
      <b-container>
        <h2 class="pageHeaderText mt-1 mb-2"><strong>TOURNAMENT CREATED!</strong></h2>
        <p class="confirm-secondaryText text-left">Below are your tournament details...</p>
      </b-container>
      <b-card
        id="confirm-card"
        class="ml-2 mr-2 mt-2 mb-5"
        bg-variant=""
        text-variant="white"
      >
        <b-row align-h="center">
          <p class="confirm-primaryText mr-2">DATE:</p>
          <p class="confirm-event-value" id="eventDate">{{ this.eventDate }}</p>
        </b-row>

        <b-row align-h="center">
          <p class="confirm-primaryText text-center mr-2">SCORING:</p>
          <p class="confirm-event-value text-center" id="scoringSystem">{{ this.eventScoringSystem }}</p>
        </b-row>

        <b-row align-h="center">
          <p class="confirm-primaryText text-center mr-2">LOCATION:</p>
          <p class="confirm-event-value text-center" id="location">{{ this.bodyOfWater }}</p>
        </b-row>

        <b-row align-h="center" class="mb-1">
          <p class="confirm-primaryText">TEAMS:</p>
        </b-row>
        
        <b-button
          pill
          disabled
          id="eventTeam"
          v-for="team in eventTeams"
          :key="team.name"
          class="mt-1 mr-2 mb-2"
        >
          {{ team.name }}
        </b-button>
      
        <b-row class="dflex justify-content-center align-items-center">
          <h1 id="eventId" class="mt-3 confirmEventID">Event: <span id="eventCode" class="confirm-id-colors">{{ this.eventID }}</span>
            <b-button size="sm ml-3 mr-1" variant="outline-light" @click="copyEventIdToBuffer">
              <b-icon icon="clipboard"></b-icon>
            </b-button>
          </h1>
        </b-row>

        <b-row class="dflex justify-content-center">
          <p class="confirm-copyEventText" id="copyEventID"></p>
        </b-row>

        <hr class="mt-3 mb-3 globalSeparator" />
        <p class="confirm-secondaryText">Please share the <strong>Event Code</strong> with your participants. They will use it to join the tournament on the day of the event.</p>

        <div v-if="this.eventIsToday">
          <p class="confirm-event-is-today">Your tournament is TODAY!</p>
          <b-button
            class=""
            id="joinNow"
            variant="success"
            size="lg"
            @click="joinEvent()"
          >
            JOIN NOW
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { beautifyDate } from '../js/utilities.js';
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav,
  },
  data() {
    return {
      eventIsToday: false,
      showDismissibleAlert: false
    };
  },
  created() {
    if (!this.eventID) {
      this.$router.push({ path: '/' });
    } else {
      this.checkIfEventIsToday();
    }
  },
  computed: {
    eventTeams() {
      return this.$store.getters['getEventTeams'];
    },
    eventType() {
      return this.$store.getters['getEventType'];
    },
    adminID() {
      return this.$store.getters['getAdminID'];
    },
    eventID() {
      return this.$store.getters['getEventID'];
    },
    eventDate() {
      return beautifyDate(this.$store.getters['getEventDate']);
    },
    eventScoringSystem() {
      return this.$store.getters['getEventScoringSystem'];
    },
    bodyOfWater() {
      return this.$store.getters['getBodyOfWater'];
    }
  },
  methods: {
    checkIfEventIsToday() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + '-' + mm + '-' + dd;

      if (today == this.$store.getters['getEventDate']) {
        this.eventIsToday = true;
      }
    },
    joinEvent() {
      localStorage.id = this.eventID;
      this.$router.push({ path: 'welcome' });
    },
    copyEventIdToBuffer() {
      navigator.clipboard.writeText(this.eventID);
      document.getElementById("copyEventID").innerHTML = "Event code copied";
      setTimeout(function () {
        document.getElementById("copyEventID").innerHTML = "";
      }, 1000);
    },
  },
};
</script>
<style>
#confirm-card {
    border: 1px solid var(--color30);
    background-color: var(--supplemental-color);
  }
.confirm-primaryText {
  color: var(--primary-font-color);
  font-size: 1.4em;
  margin: 0;
}
.confirm-secondaryText {
  color: var(--primary-font-color);
  font-size: 1.25em;
  margin: 0;
}
.confirm-event-value {
  color: var(--color30);
  font-size: 1.4em;
  margin: 0;
}
.confirmEventID {
  color: var(--primary-font-color);
  font-size: 2em;
  margin: 0;
}
.confirm-id-colors {
  color: var(--color30);
  font-weight: bold;
}
.confirm-copyEventText {
  color: var(--color30);
  font-size: .8em;
  margin: 0;
}
.confirm-event-is-today {
  color: var(--color30);
  font-size: 1.5em;
  font-weight: bold;
}
</style>
