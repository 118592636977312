<template>
  <div>
    <top-nav></top-nav>

      <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <h2 class="pageHeaderText mt-1 mb-2">CREATE TOURNAMENT</h2>

        <b-card
          id="createCard"
          class="ml-2 mr-2"
          bg-variant=""
          text-variant="white"
        >
          <form action="">
            <!-- SELECT EVENT DATE -->
            <b-form-group
              class="mt-n3 text-left"
              label="Select Date:"
              label-for="eventDate"
            >
              <b-form-datepicker
                id="eventDate"
                v-model="eventDate"
                :min="min"
              >
              </b-form-datepicker>
            </b-form-group>

            <!-- SELECT SCORING STYLE -->
            <b-form-group
              class="mt-n1 text-left"
              label="Scoring Style:"
              label-for="scoring"
            >
              <b-form-select
                id="scoring"
                v-model="scoringSelection"
                :options="scoringOptions"
              ></b-form-select>
            </b-form-group>

            <!-- ENTER BODY OF WATER -->
            <b-form-group
              class="mt-n1 text-left"
              label="Body Of Water:"
              label-for="bodyOfWater"
            >
              <b-form-input
                id="bodyOfWater"
                v-model="bodyOfWater"
                placeholder="Enter body of water"
                required
                :state="bodyOfWaterState"
                aria-describedby="bodyOfWater-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="bodyOfWater-feedback">
                {{ this.bodyOfWaterErrorMessage }}
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- ENTER EMAIL -->
            <!-- <b-form-group
              class="mt-n1 text-left"
              label="Email:"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                placeholder="Enter your email"
                required
                :formatter="formatter"
                :state="emailState"
                aria-describedby="email-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="email-feedback">
                {{ this.emailErrorMessage }}
              </b-form-invalid-feedback>
            </b-form-group> -->

            <!-- ADD TEAMS -->
            <b-row>
              <b-col cols="9" >
                <b-form-group
                  class="mt-n1 text-left"
                  label="Add Teams"
                  label-for="scoring"
                >
                  <b-form-input
                    id="addParticipants"
                    placeholder="Name"
                    v-model="teamName"
                    :state="teamNameState"
                    aria-describedby="teamName-feedback"
                    required
                    trim
                    :formatter="formatter"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-live-help teamName-feedback"
                  >
                    {{ this.teamNameErrorMessage }}
                  </b-form-invalid-feedback>
                  <b-form-text
                    class="text-white"
                    id="input-live-help"
                  >
                    Don't forget to add yourself!
                  </b-form-text>
                </b-form-group>
              </b-col>

              <b-col cols="3" class="pl-0">
                <b-button
                  id="addTeam"
                  class="mt-4"
                  variant="success"
                  :disabled="teamNameDisableState"
                  @click="addTeam()"
                >
                  ADD
                </b-button>
              </b-col>
            </b-row>

            <div v-if="this.teams.length">
              <b-button
                id="team"
                class="mt-1 mr-1"
                pill
                size="sm"
                v-for="team in teams"
                :key="team.name"
                @click="removeTeam(team.name)"
              >
                {{ team.name }}
                <b-icon
                  class="ml-1"
                  icon="x-circle"
                  aria-hidden="true"
                  style="width: 20px; height: 20px"
                ></b-icon>
              </b-button>
            </div>
            <div v-else>
              <p class="text-warning">
                Please add at least 2 teams to your tournament.
              </p>
            </div>

            <b-row align-h="center">
              <b-button
                id="createEvent"
                class="mt-3 w-100"
                variant="success"
                size="lg"
                :disabled="createDisableState"
                @click="addTournament()"
              >
                CREATE
              </b-button>
            </b-row>
            <b-row
              v-if="this.currentENV == 'development'"
              align-h="center"
            >
              <b-button
                id="createTestEvent"
                class="mt-2 w-50"
                size="sm"
                variant="info"
                @click="testAddTournament()"
              >
                TEST
              </b-button>
              
            </b-row>
          </form>
        </b-card>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import TopNav from '../components/TopNav.vue';
import { generateEventId, generateBasicId } from '../js/utilities.js';
export default {
  components: {
    TopNav,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      eventDate: null,
      teamNameErrorMessage: '',
      bodyOfWaterErrorMessage: '',
      emailErrorMessage: '',
      min: minDate,
      teamName: '',
      email: '',
      scoringSelection: 'Best Five Fish',
      scoringOptions: [
        { value: 'Best Five Fish', text: 'Total Weight of Best 5 Fish' },
        { value: 'Total Weight', text: 'Total Weight of All Fish (MLF Style)' },
      ],
      bodyOfWater: '',
      teams: [],
      teamNameMinChars: 2,
      teamNameMaxChars: 12,
      bodyOfWaterMinChars: 2,
      bodyOfWaterMaxChars: 24
    };
  },
  created() {
    this.$store.commit('UNLOAD_TOURNAMENT');
  },
  computed: {
    currentENV() {
      return process.env.NODE_ENV;
    },
    teamNameState() {
      return this.validateTeamName();
    },
    teamNameDisableState() {
      return !this.validateTeamName();
    },
    bodyOfWaterState() {
      return this.validateBodyOfWater();
    },
    emailState() {
      return this.validateEmailAddress();
    },
    createDisableState() {
      return !this.formIsValid();
    },
    getEventID() {
      return this.$store.getters['eventEventID'];
    },
  },
  methods: {
    formatter(value) {
      return value.toUpperCase();
    },
    addTeam() {
      this.teams.push({
        id: generateBasicId(16),
        name: this.teamName,
        token: '',
        eventID: this.getEventID,
      });
      this.teamName = null;
    },
    removeTeam(name) {
      this.teams = this.teams.filter(function (obj) {
        return obj.name !== name;
      });
    },
    validateTeamNameIsUnique() {
      let tmp = this.teams.filter(
        (team) => team.name === this.teamName
      );
      return tmp.length;
    },
    validateTeamNameChars() {
      var letters = /^[0-9a-zA-Z ]+$/;
      if (this.teamName.match(letters)) {
        return true;
      } else {
        return false;
      }
    },
    validateTeamName() {
      if (this.teamName) {
        if (this.validateTeamNameIsUnique()) {
          this.teamNameErrorMessage = 'Duplicate team name.';
          return false;
        } else if (this.teamName.length < this.teamNameMinChars || this.teamName.length > this.teamNameMaxChars) {
          this.teamNameErrorMessage =
            `Team names should be between ${this.teamNameMinChars} and ${this.teamNameMaxChars} characters.`;
          return false;
        } else if (!this.validateTeamNameChars()) {
          this.teamNameErrorMessage =
            'Team names can only have letters and numbers.';
          return false;
        } else {
          return true;
        }
      }
      return null;
    },
    validateBodyOfWater() {

      // CHECK IF A BODY OF WATER WAS ENTERED
      if (this.bodyOfWater == '') {
        this.bodyOfWaterErrorMessage = "Please enter body of water where tournament will take place."
        return false;
      }

      // MAKE SURE BODY OF WATER DOES NOT HAVE INVALID CHARACTERS
      if (!this.bodyOfWater.match(/^[A-Za-z0-9&\-/+. ]+$/)) {
        this.bodyOfWaterErrorMessage = 'Body of water has invalid characters.';
        return false;
      }

      // MAKE SURE BODY OF WATER IS THE RIGHT LENGTH 
      if (this.bodyOfWater.length < this.bodyOfWaterMinChars || this.bodyOfWater.length > this.bodyOfWaterMaxChars) {
        this.bodyOfWaterErrorMessage = `Body of water should be between ${this.bodyOfWaterMinChars} and ${this.bodyOfWaterMaxChars} characters.`;
        return false;
      }
      return true;
    },
    validateEmailAddress() {

      // CHECK IF A EMAIL WAS ENTERED
      if (this.bodyOfWater == '') {
        this.emailErrorMessage = "Please enter an email address."
        return false;
      }
      // CHECK IF EMAIL FORMAT IS CORRECT
      var checker = /\S+@\S+\.\S+/;
      if (!checker.test(this.email)) {
        this.emailErrorMessage = "Incorrect email format."
        return false;
      }
      return true;
    },
    formIsValid() {

      // DATE HAS BEEN SELECTED
      if (!this.eventDate) return false;

      // BODY OF WATER IS OK
      if (!this.validateBodyOfWater()) return false;

      // EMAIL IS OK
      // if (!this.validateEmailAddress()) return false;

      // WE HAVE ENOUGH TEAMS
      if (this.teams.length < 2) return false;

      // OTHERWISE WE'RE GOOD
      return true;
    },
    addTournament() {

      const newAdminID = generateEventId(7);
      let newEventID = generateEventId(7);

      let todaysDate = new Date();
      todaysDate = moment(todaysDate).format('YYYY-MM-DD');

      //if the body of water is TEST LAKE then we will consider this a test event.
      //otherwise we'll consider this to be an event from an acutal user.
      let envCreated = "PROD"
      if (this.bodyOfWater == "TEST LAKE") {
        envCreated = "TEST"
        newEventID = "XX-" + newEventID
      }

      //add the tournament
      this.$store.dispatch('addTournament', {
        createdDate: todaysDate,
        eventType: 'TOURNAMENT',
        adminID: newAdminID,
        eventID: newEventID,
        eventDate: this.eventDate,
        scoringSystem: this.scoringSelection,
        teams: this.teams,
        email: this.email,
        bodyOfWater: this.bodyOfWater,
        env: envCreated
      });
      this.$router.push({ path: 'confirm-tournament' });
    },
    testAddTournament() {
      const newAdminID = generateEventId(7);
      const newEventID = "XX-" + generateEventId(7);

      let todaysDate = new Date();
      todaysDate = moment(todaysDate).format('YYYY-MM-DD');

      let testTeams = [];
      testTeams.push({ id: generateBasicId(16), name: 'KVD', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'G-MAN', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'WHEELER', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'CLUNN', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'WATSON', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'DEFOE', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'MATT R.', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'KLEIN', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'C. ASHLEY', token: '' });
      testTeams.push({ id: generateBasicId(16), name: 'EHLER', token: '' });

      this.$store.dispatch('addTournament', {
        createdDate: todaysDate,
        eventType: 'TOURNAMENT',
        adminID: newAdminID,
        eventID: newEventID,
        eventDate: todaysDate,
        scoringSystem: 'Best Five Fish',
        teams: testTeams,
        email: 'a@a.com',
        bodyOfWater: 'Belleville Lake',
        env: "TEST"
      });
      this.$router.push({ path: 'confirm-tournament' });
    },
  },
};
</script>
<style>
#createCard {
  border: 1px solid var(--color30);
  background-color: var(--supplemental-color);
}
.text-warning {
  color: var(--color10);
}
#createEventButtons.btn {
  background-color: var(--color10);
  border-color: var(--color30);
  border: 1px solid transparent;
}
#createEventButtons:hover {
  background-color: var(--color10);
  border-color: var(--color30);
  border: 1px solid transparent;
}
</style>
