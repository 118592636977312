<template>
  <div>
    <b-modal
      id="confirm-delete"
      title="Delete Catch"
      ok-variant="success"
      cancel-variant="secondary"
      @ok="handleDelete"
    >
      <p class="teamData-sectionHeader mb-2">
        {{ this.teamName }}, are you sure you want to delete this fish?
      </p>
      <p class="teamData-sectionHeader">{{ this.deleteMessage }}</p>
    </b-modal>

    <b-modal
      id="team-data"
      size="sm"
      ok-only
      ok-variant="success"
      cancel-variant="secondary"
      header-bg-variant="light"
      header-text-variant="dark"
      body-bg-variant="light"
      body-text-variant="dark"
      footer-bg-variant="light"
      footer-text-variant="dark"
      :title="this.teamName"
    >
      <div class="container pt-0">
        <p class="teamData-sectionHeader">CATCHES: <span id="teamCatchCount">{{ this.teamStats.catches }}</span></p>
        <p class="mb-1 teamData-sectionHeader">SCORING: <span id="eventScoringSystem">{{ scoringSystem }}</span></p>
        <hr class="globalSeparator" />

        <!-- If the selected team hasn't caught any fish, display NO FISH CAUGHT
             If the selected team is the active team, give the delete option for caught fish.
             If the selected team is not the active team, do not allow deleting fish.
         -->

        <div v-if="myCatches.length > 0">
          <b-card
            v-for="fish in myCatches"
            :key="fish.id"
            class="mb-2 pl-2 pt-1 d-flex justify-content-between"
            :title="fish.time"
            border-variant="success"
            no-body
          >
            <div v-if="canIDeleteFish()">
              <p class="teamData-catchText mb-1 d-flex justify-content-between">
                <span id="fishWeight">
                  {{ fish.weight }}
                </span>@ {{ fish.time }}

                <span v-if="isBestFiveScoring()">
                  <span v-if="topFiveFish(fish)" id="top5fish">
                    <b-icon
                      class="mr-3"
                      icon="star-fill"
                      variant="warning"
                    ></b-icon>
                  </span>
                </span>
                <b-icon
                  class="mt-1 mr-3 teamData-trashIcon"
                  icon="trash"
                  variant="success"
                  @click="showDeleteFishModal(fish)"
                ></b-icon>
              </p>
            </div>
            <div v-else>
              <p class="teamData-catchText mb-0 d-flex justify-content-between">
                {{ fish.weight }} @ {{ fish.time }}
                <span v-if="isBestFiveScoring()">
                  <span v-if="topFiveFish(fish)">
                    <b-icon
                      class="mt-1 mr-3"
                      icon="star-fill"
                      variant="warning"
                    ></b-icon>
                  </span>
                </span>
              </p>
            </div>
          </b-card>

          <b-row v-if="isBestFiveScoring()">
            <p id="bestFiveFishFootnote" class="teamData-infoText mt-2">
              <b-icon
                class="mt-1 mr-1"
                icon="star-fill"
                variant="warning"
              ></b-icon>
              denotes best 5 fish by weight
            </p>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  getTeamResults,
  getAverageFishSizeInTournament,
} from '../js/standings.js';
export default {
  name: 'TeamData',
  props: ['teamName'],
  data() {
    return {
      deleteMessage: '',
    };
  },
  computed: {
    allCatches() {
      const catches = this.$store.getters['getAllCatches'];
      return catches;
    },
    teamStats() {
      let system = this.$store.getters['getEventScoringSystem'];
      let stats = getTeamResults(this.teamName, this.allCatches, system);
      return stats;
    },
    scoringSystem() {
      let system = this.$store.getters['getEventScoringSystem'];
      if (system == 'Total Weight') {
        system = 'TOTAL (ALL FISH)';
      } else {
        system = 'BEST 5 FISH';
      }
      return system;
    },
    myCatches() {
      let catches = this.$store.getters['getAllCatches'];
      catches = catches.filter((fish) => fish.name === this.teamName);

      catches.sort(function (a, b) {
        return b.timeStamp - a.timeStamp;
      });
      return catches;
    },
  },
  methods: {
    showDeleteFishModal(fish) {
      this.deleteMessage = fish.weight + ' @ ' + fish.time;
      this.fishToDelete = fish;
      this.$bvModal.show('confirm-delete');
    },
    handleDelete() {
      this.$bvModal.hide('confirm-delete');
      this.$store.dispatch('deleteFish', {
        eventID: localStorage.id,
        id: this.fishToDelete.id,
      });
    },
    canIDeleteFish() {
      if (this.teamName == localStorage.activeUser) {
        return true;
      } else {
        return false;
      }
    },
    isBestFiveScoring() {
      if (this.scoringSystem == 'BEST 5 FISH') {
        return true;
      } else {
        return false;
      }
    },
    topFiveFish(fish) {
      if (this.myCatches.length <= 5) {
        // if we don't have at least 6 fish there's no reason to highlight anything
        return false;
      } else {
        let bestFive = this.myCatches.slice();
        bestFive = bestFive
          .sort(function (a, b) {
            return b.weight - a.weight;
          })
          .slice(0, 5);

        // is the fish in the best 5?
        if (bestFive.find((best) => best.timeStamp === fish.timeStamp)) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>
<style>
.teamData-sectionHeader {
  font-size: 1.1em;
  /* color: white; */
  margin: 0 0 0 0;
}

.teamData-catchText {
  font-size: 1.1em;
  font-weight: 400;
  /* color: white; */
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.teamData-infoText {
  font-size: 1.2em;
  font-weight: 400;
  /* color: white; */
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.teamData-trashIcon {
  width: 17px;
  height: 17px;
}
</style>
