<template>
  <div>
    <top-nav></top-nav>

    <!-- HERO SECTION -->
    <div class="darkSection text-white">
      <b-container>
        <b-row class="ml-2">
          <b-col class="d-flex align-items-center" cols="8">
            <b-row class="mb-2 d-flex align-items-center">
              <h1 class="home-heroText">
                Competitive fishing just got BETTER!
              </h1>
              <h4 class="home-heroSubText mr-5">LIVE tournament scoreboards, just like the pros, for everyone and it's <strong>FREE</strong>!</h4>
              <!-- <b-button href="#howCanItGetBetter" variant="danger">Learn More</b-button> -->
            </b-row>
          </b-col>
          <b-col cols="4">
            <b-img :src="require('../images/hero7smaller1.png')" fluid alt="Responsive image"></b-img>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- HOW CAN IT GET BETTER? -->
    <div id="howCanItGetBetter" class="lightSection">
      <b-container>
          <h1 class="home-sectionTitle">Next Level Fun Fishing</h1>
          <h4 class="home-sectionText">
            Do you want to have <strong>FOR FUN</strong> tournaments with your friends but also have a LIVE scoreboard like the pros have on TV? You've come to the right place.
          </h4>
          <h4 class="home-sectionText">
            
          </h4>

          <h2 class="home-sectionText"><strong>CastClash</strong> to the rescue!</h2>
      </b-container>
    </div>
    

    <!-- BENEFITS -->
    <div class="darkSection">

      <b-container>
      <h1 class="home-sectionTitle text-white">What We Offer</h1>

      <b-row class="justify-content-around">
        <b-card
          title="Fun Events With Friends"
          :img-src="require('../images/mockup1.png')"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem"
          class="mb-2"
        >
          <b-card-text>
            This is the best part. Want to fish against your friends? This is your tool. Add your fish as you catch them and the scoreboard updates!
            CastClash tournaments support either <strong>Best 5 Fish</strong> or <strong>Total Weight of All Fish</strong> (MLF Style) scoring.
          </b-card-text>

          <b-button
            href="#"
            variant="danger"
            to="/create-tournament"
            >Create Your First Tournament</b-button
          >
        </b-card>

        <b-card
          title="Live Tournament Weigh In Leaderboard"
          :img-src="require('../images/mockup5.png')"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem"
          class="mb-2"
        >
          <b-card-text>
            Tournament Directors, host your weigh ins on Cast Clash. All of your anglers can watch the standings update LIVE as you weigh in teams and enter thier weights.
          </b-card-text>

          <b-button
            href="#"
            variant="danger"
            to="/create-weigh-in"
            >Host A Tournament Weigh In</b-button
          >
        </b-card>

        <b-card
          title="Watch From Anywhere"
          :img-src="require('../images/mockup2.png')"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem"
          class="mb-2"
        >
          <b-card-text>
            Not fishing but want to follow a CastClash tournament or weigh in? You can follow along from anywhere!
            All you need is an event code and you keep an eye on the action.
          </b-card-text>

          <b-button
            href="#"
            variant="danger"
            to="/join"
            >Join Event As A Viewer</b-button
          >
        </b-card>

        
      </b-row>
    </b-container>
    </div>

     <!-- BUILT BY FISHERMEN -->
     <div id="about" class="lightSection">
      <b-container>
          <h1 class="ml-2 home-sectionTitle">Built by Tournament Fishermen</h1>
          <h5 class="home-sectionText">
            <strong>CastClash</strong> was born when a group of experienced tournament bass
            fishermen wanted to have our own "for fun" tournaments with our most trusted friends.
            We thought it would be awesome to have a live scoreboard like we see on TV. So we built it and tested it
            for two years. We quickly realized that fishing became more fun and we knew we had to share it with the world.
          </h5>
          <h5 class="home-sectionText">
            Our offerings are designed to enhance the fishing experience of everyday
            Anglers and Tournament Directors. If you like what we do, buy us a cup of coffee. We would greatly appreciate it.
          </h5>

        <buy-me-a-coffee></buy-me-a-coffee>
      </b-container>
    </div>

    <!-- IT'S EASY TO USE -->
    <div class="darkSection text-white">
      <b-container>
        <h1 class="ml-2 home-sectionTitle">It's Super Easy To Use and FREE!</h1>
        <h5 class="ml-2 home-sectionText">
         It only takes a few minutes to setup a new tournament or weigh in. Once created, we'll give you an event code that allows you to access your event on the day of.
        </h5>
        <!-- <b-row align-h="center">
          <b-button size="lg" to="/create-tournament" variant="danger">Create a Tournament, It's FREE</b-button>
        </b-row>
        <b-row align-h="center">
          <h5 class="ml-2 home-sectionText">
            OR
          </h5>
        </b-row>
        <b-row align-h="center">
            <b-button size="lg" to="/create-weigh-in" variant="danger">Host a Weigh In, It's also FREE</b-button>
        </b-row> -->
      </b-container>
    </div>

    <div class="lightSection">
      <b-container>
        <h1 class="ml-2 home-sectionTitle">Testimonials</h1>
        <blockquote class="blockquote text-left">
          <p class="mb-0">
            "We are a small group of anglers that have been testing out CastClash going on two years now. 
            CastClash brings a new exciting aspect to our tournaments that we've never had before.
            Checking the live scoreboard gives me the opportunity to change my strategy if needed."
          </p>
          <footer class="blockquote-footer">Joe B. <cite title="Source Title">Tin Boat Tour (small tournament trail in S.E. Michigan)</cite></footer>
        </blockquote>
      </b-container>
    </div>

   
    <!-- FAQ -->
    <div id="faq" class="lightSection">
      <b-container>
        <h1 class="ml-2 home-sectionTitle">FAQ - Everything You Need to Know</h1>
        <div class="accordion" role="tablist">

          <!-- Do Need to Install Anything? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button class="text-left" size="lg" block v-b-toggle.item0 variant="light">
                <b-icon class="mr-3" icon="plus-square" aria-hidden="true"></b-icon>
                <strong>Do I Need to Install an App from the App Store?</strong>
              </b-button>
            </b-card-header>
            <b-collapse id="item0" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <h5 class="text-left">Nope! Everything you need is here on CastClash.com! No apps to install or update.</h5>
              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- What Do I Need? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button class="text-left" size="lg" block v-b-toggle.item1 variant="light">
                <b-icon class="mr-3" icon="plus-square" aria-hidden="true"></b-icon>
                <strong>What Do I Need to Use CastClash?</strong>
              </b-button>
            </b-card-header>
            <b-collapse id="item1" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <h5 class="text-left">All you need is a digital fish scale to weigh your fish. We allow you to enter weights in a decimal format (i.e. 3.79)
                  or you can enter a weight in pounds and ounces (i.e. 3 pounds, 7 oz).
                </h5>
              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- How Much Doe It Cost? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button class="text-left" size="lg" block v-b-toggle.item2 variant="light">
                <b-icon class="mr-3" icon="plus-square" aria-hidden="true"></b-icon>
                <strong>How Much Does It Cost?</strong>
              </b-button>
            </b-card-header>
            <b-collapse id="item2" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <h5 class="faqText text-left">
                  For now, CastClash is free for 2024! However we have invested 100's of hours to create this for
                  fishermen and expect to ask a small fee in the future. Our costs are not free for us to build, maintain and improve this site.
                  If you like our product we ask that you donate the price of a cup of coffee everytime you use it. It would help us out a lot!
                </h5>
                <buy-me-a-coffee></buy-me-a-coffee>
              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- What Are The Rules? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button class="text-left" size="lg" block v-b-toggle.item0 variant="light">
                <b-icon class="mr-3" icon="plus-square" aria-hidden="true"></b-icon>
                <strong>What Are The Rules for Tournaments?</strong>
              </b-button>
            </b-card-header>
            <b-collapse id="item0" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <h5 class="text-left">
                  The rules are up to you. When you create the event you can select your scoring system: Best 5 Fish or Total Weight of all fish.
                  You can decide, for example, what is the minimum weight for a fish and when the start and end time should be, etc.
                </h5>
              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- How Much Does It Cost? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button class="text-left" size="lg" block v-b-toggle.item3 variant="light">
                <b-icon class="mr-3" icon="plus-square" aria-hidden="true"></b-icon>
                <strong>Should I Use CastClash for Real Tournaments That Pay Money?</strong>
              </b-button>
            </b-card-header>
            <b-collapse id="item3" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <h5 class="faqText text-left">
                  <strong>Nope!</strong> Our Tournament Mode is just for "fun fishing". The idea is to only use it with people you trust. We kept the app simple
                  to prevent users from creating user accounts (yuck!) for security purposes. Money just ruins the fun and changes people. Add money to your events at your own risk!
                </h5>
              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- How Long Will My Data Last In CastClash? -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button class="text-left" size="lg" block v-b-toggle.item4 variant="light">
                <b-icon class="mr-3" icon="plus-square" aria-hidden="true"></b-icon>
                <strong>How Long Will My Tournament Data Live In CastClash?</strong>
              </b-button>
            </b-card-header>
            <b-collapse id="item4" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <h5 class="faqText text-left">
                 For now we will keep all event data for 30 days. Please make sure to screenshot any result scoreboards that you don't want to lose.
                </h5>
              </b-card-body>
            </b-collapse>
          </b-card>

          <!-- Hosting Weigh Ins -->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button class="text-left" size="lg" block v-b-toggle.item5 variant="light">
                <b-icon class="mr-3" icon="plus-square" aria-hidden="true"></b-icon>
                <strong>I'm a Tournament Director, how can I host a Weigh In?</strong>
              </b-button>
            </b-card-header>
            <b-collapse id="item5" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <h5 class="faqText text-left">
                 CastClash allows Tournament Directors to manage the weigh in process. By sharing the Event Code with the anglers, they can follow along with a live scoreboard to
                 see who's in first and what the biggest fish is. Tournament Directors simply enter each team's weight and big fish into CastClash and the standings will update for
                 everyone instantly.
                </h5>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-container>
    </div>
    <footer class="footer mt-auto py-3">
      <b-container>
        <b-row class="dflex justify-content-between align-items-center ml-2 mr-2">
          <h4 class="footerText text-left">CASTCLASH.COM</h4>
          <b-link @click="goToPage('terms-of-service')">Terms of Service</b-link>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
import EventIcons from '../components/EventIcons.vue';
import BuyMeACoffee from '../components/BuyMeACoffee.vue';
export default {
  components: {
    TopNav,
    EventIcons,
    BuyMeACoffee
  },
  metaInfo: {
    title: 'Fishing Tournament | CastClash.com',
    meta: [
      { name: 'robots', content: 'index, follow' },
      { name: 'description', content: 'Easily host fishing tournaments with your friends with a live scoreboard. It makes competitive fishing even more fun!' },
    ],
  },
  computed:  {
    currentENV() {
      return process.env.NODE_ENV;
    },
    isMobile() {
      // console.log(screen.width);
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }

  },
  methods: {
    goToPage(page) {
      this.$router.push({ path: page });
    }
  }
};
</script>
<style>
    /* Default styles for desktop screens */
    .desktop-text {
        display: block;
    }

    .mobile-text {
        display: none;
    }

    /* Media query for screens smaller than 768px */
    @media (max-width: 578px) {
        .desktop-text {
            display: none;
        }

        .mobile-text {
            display: block;
        }
    }
  .home-icon {
    height: 50px;
    width: 50 px;
    color: var(--color30);
  }
  .home-iconText {
    font-family: var(--primary-font);
    color: var(--color30);
  }
  #main.jumbotron {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 1px;
    /* background-image:url(../images/hero.jpg); */
    /* background-size: cover; */
  }
  #builtBy.jumbotron {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 1px;
  }
  #benefits.jumbotron {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 1px;
  }
  #howItWorks.jumbotron {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 1px;
  }
  #allYouNeed.jumbotron {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 1px;
  }
  /* font-size: 6vw; */
  .home-heroText {
    font-size: 7vw;
    font-weight: bolder;
    text-align: left;
  }
  .home-heroSubText {
    font-weight: normal;
    text-align: left;
  }
  
  .home-sectionTitle {
    margin-top: 0.5em;
    font-weight: bolder;
    text-align: left;
  }
  .home-sectionText {
    font-family: 'Atkinson Hyperlegible', sans-serif;
    margin-top: 0.5em;
    text-align: left;
  }
  
.darkSection{
  background-color: --var(-color60);
  padding-top: 20px;
  padding-bottom: 20px;
}
.lightSection {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.faqText {
  font-family: 'Atkinson Hyperlegible', sans-serif;
}
.footerText {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight:bold;
  font-style: normal;
  color: white;
  margin: 0;
}

</style>