<template>
  <div>
    <add-fish-modal></add-fish-modal>
    <top-nav></top-nav>

    <!-- HEADER ==================================== -->
    <div class="pl-0 pr-0 mx-auto col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <b-container>
        <b-row>
          <h1 id="location" class="mx-auto text-white">{{bodyOfWater}}</h1>
        </b-row>

        <b-row align-h="center">
          <p class="liveScorer-eventInfoText mr-2">DATE:</p>
          <p id="eventDate" class="liveScorer-highlighted-eventInfoText">{{ eventDate }}</p>
        </b-row>

        <b-row align-h="center">
          <p class="liveScorer-eventInfoText mr-2">EVENT:</p>
          <p id="eventID" class="liveScorer-highlighted-eventInfoText">{{ eventID }}</p>
        </b-row>
        
        <b-row align-h="center">
          <p class="liveScorer-eventInfoText mr-2">SCORING:</p>
          <p id="scoringSystem" class="liveScorer-highlighted-eventInfoText">{{ scoringSystem }}</p>
        </b-row>

        <b-row v-if="activeTeam == 'VIEW ONLY'">
          <h2 class="mx-auto text-white pageHeaderText">VIEW EVENT</h2>
        </b-row>

        <b-row class="mt-3 dflex justify-content-around align-items-center" v-else>

          <h3 class="text-center text-white">TEAM: <span id="activeTeam">{{ activeTeam }}</span></h3>
          <b-button
            id="add-fish"
            variant="success"
            v-b-modal.add-fish-modal
            class=""
            size="lg"
          >
            ADD FISH
          </b-button>
        </b-row>

        <!-- LEADERBOARD & CATCH LOG ==================================== -->
        <tabs
          class="mb-3 mt-5"
          :data="['LEADER BOARD', 'CATCH LOG']"
          main-color="#000000"
          @clickedTab="tabsHandler"
        >
        </tabs>
        <div v-if="this.tabIndex == 0">
          <leader-board
            :standings="currentStandings"
            class="mt-3"
          ></leader-board>
        </div>

        <div v-else-if="this.tabIndex == 1">
          <catch-log></catch-log>
        </div>

        <buy-me-a-coffee-card></buy-me-a-coffee-card>
    </b-container>
  </div>
  </div>
</template>

<script>
import AddFishModal from '../components/AddFishModal.vue';
import TopNav from '../components/TopNav.vue';
import Tabs from '../components/UnderlineTabs.vue';
import LeaderBoard from '../components/LeaderBoard.vue';
import CatchLog from '../components/CatchLog.vue';
import BuyMeACoffeeCard from '../components/BuyMeACoffeeCard.vue';
import VuejsDialog from 'vuejs-dialog';
import { beautifyDate } from '../js/utilities.js';

export default {
  components: {
    AddFishModal,
    TopNav,
    Tabs,
    LeaderBoard,
    CatchLog,
    BuyMeACoffeeCard
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  created() {
  },
  mounted() {
    if (localStorage.id != '') {
      this.$store.dispatch('loadTournament', localStorage.id)
    } else {
      // this prevents a user from typing in the live-scorer url directly
      // when there is no localStorage.id
      this.$router.push({path: 'join'});
    }
  },
  beforeRouteLeave(to, from, next) {
    let options = {
      okText: 'LEAVE',
      cancelText: 'STAY',
      customClass: '',
    };

    // Prevent the user from leaving the page unless they confirm.
    if (localStorage.id == '') {
      next();
    } else {
      this.$dialog
        .confirm('Exit Tournament?', options)
        .then(function () {
          localStorage.activeUser = '';
          localStorage.id = '';
          console.log('LEAVING EVENT');
          next();
        })
        .catch(function () {
          console.log('STAYING IN THE EVENT');
        });
    }
  },
  watch: {
    eventDateValid(value) {
      if (value) {
        console.log('Event Is Today');
      } else {
        console.log('Event Has Ended');
      }
    },
  },
  computed: {
    activeTeam() {
      return localStorage.activeUser;
    },
    eventID() {
      return this.$store.getters['getEventID'];
    },
    bodyOfWater() {
      return this.$store.getters['getBodyOfWater'];
    },
    eventDateValid() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + '-' + mm + '-' + dd;
      today = '2024-03-02';

      if (today == this.$store.getters['getEventDate']) {
        return true;
      } else {
        return false;
      }
    },
    scoringSystem() {
      let system = this.$store.getters['getEventScoringSystem'];
      if (system == 'Total Weight') {
        system = 'TOTAL (ALL FISH)';
      } else {
        system = 'BEST 5 FISH';
      }
      return system;
    },
    bigFish() {
      return this.$store.getters['getBigFish'];
    },
    allCatches() {
      let catches = this.$store.getters['getAllCatches'];
      catches.sort(function (a, b) {
        return b.timeStamp - a.timeStamp;
      });
      return catches;
    },
    currentStandings() {
      return this.$store.getters['getCurrentStandings'];
    },
    eventDate() {
      return beautifyDate(this.$store.getters['getEventDate']);
    },
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/' });
    },
    tabsHandler(selectedTabData) {
      this.tabIndex=  selectedTabData.index
    },
  },
};
</script>
<style>
#catchLog-card {
  border: 1px solid var(--color30);
  background-color: var(--supplemental-color);
}
.catchLog-text {
  font-family: var(--leaderboard-font);
  font-size: 1.15em;
  font-weight:bold;
  color: var(--primary-font-color);
  margin: 0;
  padding: 0;
}

.liveScorer-catchLogText {
  font-family: var(--leaderboard-font);
  color: var(--primary-font-color);
  font-size: 1.0em;
  margin: 0 0 5px 0;
}

.liveScorer-eventInfoText {
  color: var(--primary-font-color);
  font-size: 1.25em;
  margin: 0 0 5px 0;
}
.liveScorer-highlighted-eventInfoText {
  color: var(--color30);
  font-size: 1.25em;
  margin: 0 0 5px 0;
}

.liveScorer-bigFish {
  color: goldenrod;
}

</style>